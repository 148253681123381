import { useEffect } from 'react';
import { useCity } from './useCity';
import { get } from 'lodash';

function usePageTitle(title) {
  const cityName = get(useCity(), 'cityConfig.metadata.name');

  useEffect(() => {
    _setPageTitle(_templatePageTitle(title));

    function _setPageTitle(pageTitle) {
      window.document.title = pageTitle;
    }

    function _templatePageTitle(pageName) {
      return `${pageName} | ${cityName}`;
    }
  }, [title, cityName]);
}

export { usePageTitle };
